<template>
  <v-card>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> step 1 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> step 2 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3"> step 3 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4"> step 4 </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card-title class="justify-center">
            <span class="text-h5"><strong>DONOR MEDICAL INFORMATION</strong></span>
          </v-card-title>
          <v-row class="ml-3 mr-5 pt-3 mb-3">
            <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.donor_name" label="Donor Name" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="3">
              <p class="body-2 mt-1">Death Verified By:*</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="deathVerifiedList"
                hide-details
                v-model="preValueList.death_verified_by"
                class="mt-0 pt-0"
                :readonly="readOnlyField1 === true"
                 :error="errr === false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="3">
              <p class="body-2 mt-1">Cooling Method:*</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                :items="collingMethodList"
                hide-details
                v-model="preValueList.cooling_method"
                class="mt-0 pt-0"
                :readonly="readOnlyField1 === true"
                 :error="errr1 === false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-3" v-if="preValueList.cooling_method == 'Refrigeration'">
            <v-col cols="12" md="3">
              <p class="body-2 mt-1">Date-Time Body Subjected to Refrigeration:*</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-datetime-picker
                v-model="subjectedRefrigeration"
                 date-format="dd-MM-yyyy"
                time-format="HH:mm:ss"
                :readonly="readOnlyField1 === true"
                 :error="errr2 === false"
              ></v-datetime-picker>
            </v-col>
          </v-row>
          <v-row class="mx-3" v-if="preValueList.cooling_method == 'Refrigeration'">
            <v-col cols="12" md="3">
              <p class="body-2 mt-1">Date-Time Body Removed from Refrigeration:*</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-datetime-picker
                v-model="removedRefrigeration"
                 date-format="dd-MM-yyyy"
                time-format="HH:mm:ss"
                :readonly="readOnlyField1 === true"
                 :error="errr3 === false"
              ></v-datetime-picker>
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-1">
            <v-col cols="12" md="12" class="text-right">
              <v-btn class="mr-3" color="primary" v-if="saveBtn" @click="formCFirst()"> Save </v-btn>
              <v-btn color="primary" text @click="e1 = 2"> Next </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card-title class="justify-start">
            <span class="font-weight-semibold">PHYSICAL ASSESSMENT</span>
          </v-card-title>
          <v-row class="mx-3 pt-3">
            <v-col cols="12" md="2">
              <p class="mt-4">Basic Hygiene:*</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                :items="basicHygieneList"
                hide-details
                v-model="preValueList.basic_hygiene"
                :readonly="readOnlyField1 === true"
                :error="errr4 === false"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.basic_hygiene == 'Poor'">
              <v-text-field
                v-model="preValueList.describe_if_poor"
                label="Basic Hygiene Describe*"
                :readonly="readOnlyField1 === true"
                :error="errr5 === false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="2">
              <p class="mt-1">Body Profile:*</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                :items="bodyProfileList"
                hide-details
                v-model="preValueList.body_profile"
                :readonly="readOnlyField1 === true"
                :error="errr6 === false"
                class="mt-0 pt-0"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="preValueList.hospital_staff_identifying_body"
                label="Hospital or other staff Identifying body"
                :readonly="readOnlyField1 === true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="preValueList.technician_identifying_body"
                label="Technician Identifying Body"
                :readonly="readOnlyField1 === true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mt-1">Technician Performing Assessment:*</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.technician_performing_assessment"
                class="pt-0 mt-0"
                :readonly="readOnlyField1 === true"
                :error="errr7 === false"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-3">
            <v-col cols="12" md="8">
              <p class="mb-1">Lymphadenopathy(i.e Enlaged Lymph Nodes)*</p>
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.lymphadenopathy"
                :readonly="readOnlyField1 === true"
                :error="errr8 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mb-1">Jaundice/Icterus ?*</p>
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.jaundice_or_icterus"
                :readonly="readOnlyField1 === true"
                :error="errr9 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8">
              <p class="mb-1">Needle Tracks or other Signs of IV drug use*</p>
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.needle_tracks_or_ivdrug_use"
                :readonly="readOnlyField1 === true"
                :error="errr10 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mb-1">White Spots in Mouth ?*</p>
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.white_spots_in_mouth"
                :readonly="readOnlyField1 === true"
                :error="errr11 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8">
              <p class="mb-1">Blue/purple or gray/black spota/lesions*</p>
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.blue_purple_or_gray_black_spots_lesions"
                :readonly="readOnlyField1 === true"
                :error="errr12 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mb-1">Evidence of blood loss*</p>
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.evidence_of_blood_loss"
                :readonly="readOnlyField1 === true"
                :error="errr13 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-2">
            <!-- <div class="text-h6 ml-2"><strong>On skin consistent with Kapsosi's Sarcoma:</strong></div> -->
            <v-col cols="12" md="5">
              <p class="mb-1 mt-1">On skin consistent with Kapsosi's Sarcoma*</p>
              <!-- </v-col>
            <v-col cols="12" md="7"> -->
              <v-radio-group
                row
                class="mt-0 mb-0"
                hide-details
                v-model="preValueList.onskin_consistent_with_kaposis_sarcoma"
                :readonly="readOnlyField1 === true"
                :error="errr14 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="8">
              <p class="mb-1">Evidance of smallpox vaccination or scab*</p>
              <v-radio-group
                row
                class="mt-0 mb-0"
                hide-details
                v-model="preValueList.evidence_of_samllpox_vaccination_scab"
                :readonly="readOnlyField1 === true"
                :error="errr15 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mb-1">Red/skin lesions*</p>
              <v-radio-group
                row
                class="mt-0"
                hide-details
                v-model="preValueList.red_skin_lesions"
                :readonly="readOnlyField1 === true"
                :error="errr16 === false"

              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            class="mx-3"
            v-if="
              preValueList.lymphadenopathy == 'Yes' ||
              preValueList.jaundice_or_icterus == 'Yes' ||
              preValueList.needle_tracks_or_ivdrug_use == 'Yes' ||
              preValueList.white_spots_in_mouth == 'Yes' ||
              preValueList.blue_purple_or_gray_black_spots_lesions == 'Yes' ||
              preValueList.evidence_of_blood_loss == 'Yes' ||
              preValueList.onskin_consistent_with_kaposis_sarcoma == 'Yes' ||
              preValueList.evidence_of_samllpox_vaccination_scab == 'Yes' ||
              preValueList.red_skin_lesions == 'Yes'
            "
          >
            <v-col cols="12" md="4">
              <p class="mt-1">Explain if any answers are <strong>"Yes"</strong></p>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="preValueList.explain_if_any_answers"
                class="pt-0 mt-0"
                :readonly="readOnlyField1 === true"
                
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="8">
              <p class="mb-1">Body piercing:*</p>
              <v-radio-group row class="mt-0" v-model="preValueList.body_piercing" :readonly="readOnlyField1 === true" :error="errr17 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mb-1">Melanomas,Moles,warts,rashes:*</p>
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.melanomus_moles_warts_rashes"
                :readonly="readOnlyField1 === true"
                :error="errr18 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8">
              <p class="mb-1">Hospital interventions: Defibrillator marks, IV lines, ET tubes, drains etc:*</p>
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.hospital_interventions"
                :readonly="readOnlyField1 === true"
                :error="errr19 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mb-1">Abrasion,brusing etc:*</p>
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.abrasion_brusing_etc"
                :readonly="readOnlyField1 === true"
                :error="errr20 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mt-1">Gross Physical examination findings:</p>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="preValueList.gross_physical_exam_findings"
                hide-details
                class="mt-0 pt-0"
                :readonly="readOnlyField1 === true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-1">
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" text class="float-left" @click="refresh()"> Previous </v-btn>
              <v-btn color="primary" class="mr-3" v-if="saveBtn" @click="formCSecond()"> Save </v-btn>
              <v-btn color="primary" text @click="e1 = 3"> Next </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card-title class="justify-start">
            <span class="font-weight-semibold">EYE ASSESSMENT</span>
          </v-card-title>
          <v-row class="mx-3 pt-3">
            <v-col cols="12" md="4"> </v-col>
            <v-col cols="12" md="4" class="d-flex">
              <v-checkbox
                hide-details
                class="mt-0 pt-0"
                v-model="preValueList.OS_check"
                @change="checkOsOd()"
                @click="checkOdOsTrue()"
                :readonly="readOnlyField === true"
              ></v-checkbox>
              <span class="mb-0"><strong> OS</strong></span>
            </v-col>
            <v-col cols="12" md="4" class="d-flex">
              <v-checkbox
                hide-details
                class="mt-0 pt-0"
                v-model="preValueList.OD_check"
                @change="checkOsOd()"
                @click="checkOdOsTrue()"
                :readonly="readOnlyField === true"
              ></v-checkbox>
              <span class="mb-0"><strong> OD</strong></span>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-divider class="mb-6"></v-divider>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mt-1">Condition of Superior Lid:*</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_superior_lid_os"
                hide-details
                :items="Condition_of_Superior_OS"
                class="mt-0 pt-0"
                label="Condition of Superior Lid OS"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
                :error="errr21 === false"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_superior_lid_od"
                hide-details
                :items="Condition_of_Superior_OD"
                class="mt-0 pt-0"
                label="Condition of Superior Lid OD"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
                :error="errr32 === false"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <p class="mt-1">Comments:</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.condition_of_superior_lid_os_comments"
                label="OS Comments"
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.condition_of_superior_lid_od_comments"
                label="OD Comments"
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-divider class="mb-6"></v-divider>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mt-1">Condition of Inferior Lid:*</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_inferior_lid_os"
                hide-details
                :items="Condition_of_Inferior_OS"
                class="mt-0 pt-0"
                label="Condition of Inferior Lid OS"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
                :error="errr22 === false"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_inferior_lid_od"
                hide-details
                :items="Condition_of_Inferior_OD"
                class="mt-0 pt-0"
                label="Condition of Inferior Lid OD"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
                :error="errr33 === false"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mt-1">Comments:</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.condition_of_inferior_lid_os_comments"
                label="OS Comments"
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.condition_of_inferior_lid_od_comments"
                label="OD Comments"
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <p class="mt-1">Condition of Conjunctiva:*</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_conjunctiva_os"
                hide-details
                :items="Condition_of_Conjunctiva_OS"
                label="Condition of Conjunctiva OS"
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
                :error="errr23 === false"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_conjunctiva_od"
                hide-details
                :items="Condition_of_Conjunctiva_OD"
                label="Condition of Conjunctiva OD"
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
                :error="errr34 === false"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mt-1">Comments:</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.condition_of_conjunctiva_os_comments"
                label="OS Comments"
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.condition_of_conjunctiva_od_comments"
                label="OD Comments"
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <p class="mt-1">Condition of Epithelium:*</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_epithelium_os"
                hide-details
                :items="Condition_of_Epithelium_OS"
                label="Condition of Epithelium OS"
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
                :error="errr24 === false"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_epithelium_od"
                hide-details
                :items="Condition_of_Epithelium_OD"
                label="Condition of Epithelium OD"
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
                :error="errr35 === false"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mt-1">Comments:</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.condition_of_epithelium_os_comments"
                label="OS Comments"
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.condition_of_epithelium_od_comments"
                label="OD Comments"
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-6">
            <v-divider class="mb-6"></v-divider>
          </v-row>

          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mt-1">Condition of Corneal Stroma:*</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_corneal_stroma_os"
                hide-details
                :items="Condition_of_Corneal_Stroma_OS"
                label="Condition of Corneal Stroma OS"
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
                :error="errr25 === false"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_corneal_stroma_od"
                hide-details
                :items="Condition_of_Corneal_Stroma_OD"
                label="Condition of Corneal Stroma OD"
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
                :error="errr36 === false"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mt-1">Comments:</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.condition_of_corneal_stroma_os_comments"
                label="Comments OS"
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.condition_of_corneal_stroma_od_comments"
                label="Comments OD"
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-6">
            <v-divider class="mb-6"></v-divider>
          </v-row>

          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mb-0">Condition of Intraocular:*</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_intraocular_os"
                :items="Condition_of_Intraocular_OS"
                label="Condition of Intraocular OS"
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
                :error="errr26 === false"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.condition_of_intraocular_od"
                :items="Condition_of_Intraocular_OD"
                label="Condition of Intraocular OD"
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
                :error="errr37 === false"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="mx-6">
            <v-divider class="mb-6"></v-divider>
          </v-row>

          <v-row class="mx-3 mb-3">
            <v-col cols="12" md="4">
              <p class="mb-1">Iris Color:*</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.iris_color_os"
                hide-details
                :items="Iris_Color_OS"
                label="Iris Color OS:"
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
                :error="errr27 === false"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="preValueList.iris_color_od"
                hide-details
                :items="Iris_Color_OD"
                label="Iris Color OD:"
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
                :error="errr38 === false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-3">
            <v-col cols="12" md="4">
              <p class="mt-1">Pupil Diameter(mm)*</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.pupil_diameter_mm_os"
                label="Pupil Diameter(mm) OS"
                hide-details
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
                :error="errr28 === false"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.pupil_diameter_mm_od"
                label="Pupil Diameter(mm) OD"
                hide-details
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
                :error="errr39 === false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-3">
            <v-col cols="12" md="4">
              <p class="mt-1">Abnormalities*</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.abnormalities_os"
                label="Abnormalities OS"
                hide-details
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true"
                :readonly="readOnlyField === true"
                
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.abnormalities_od"
                label="Abnormalities OD"
                hide-details
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true"
                :readonly="readOnlyField === true"
                
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-3">
            <v-col cols="12" md="4">
              <p class="mt-1">Evidence of Surgery*</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.evidence_of_surgery_os"
                :readonly="readOnlyField === true"
                v-if="preValueList.OS_check == true"
                @change="rest(preValueList.evidence_of_surgery_os)"
                :error="errr30 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group
                row
                class="mt-0"
                v-model="preValueList.evidence_of_surgery_od"
                :readonly="readOnlyField === true"
                v-if="preValueList.OD_check == true"
                @change="rest1(preValueList.evidence_of_surgery_od)"
                :error="errr41 === false"
              >
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            class="mx-6"
            v-if="preValueList.evidence_of_surgery_os == 'Yes' || preValueList.evidence_of_surgery_od == 'Yes'"
          >
            <v-divider class="mb-6"></v-divider>
          </v-row>
          <v-row
            class="mx-3 mb-3"
            v-if="preValueList.evidence_of_surgery_os == 'Yes' || preValueList.evidence_of_surgery_od == 'Yes'"
          >
            <v-col cols="12" md="4">
              <p class="mt-1">If Yes Specify</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.if_yes_specify_os"
                label="Specify OS"
                hide-details
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true && preValueList.evidence_of_surgery_os == 'Yes'"
                :readonly="readOnlyField === true"
                :error="errr31 === false"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.if_yes_specify_od"
                label="Specify OD"
                hide-details
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true && preValueList.evidence_of_surgery_od == 'Yes'"
                :readonly="readOnlyField === true"
                :error="errr42 === false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-divider class="mb-6"></v-divider>
          </v-row>
          <v-row class="mx-3 mb-3">
            <v-col cols="12" md="4">
              <p class="mt-1">Performed By</p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.performed_by"
                label="Performed By OS"
                hide-details
                class="mt-0 pt-0"
                readonly
                v-if="preValueList.OS_check == true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.performed_by"
                label="Performed By OD"
                hide-details
                class="mt-0 pt-0"
                readonly
                v-if="preValueList.OD_check == true"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-divider class="mb-6"></v-divider> -->
          <v-row class="mx-3 mb-3">
            <v-col cols="12" md="4">
              <!-- <p class="mt-1">Assisted By</p> -->
              <v-checkbox
                hide-details
                label="Assisted By"
                class="mt-0 pt-0"
                v-model="preValueList.assisted_by2"
                :readonly="readOnlyField === true"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.assisted_by"
                label="Assisted By OS"
                hide-details
                class="mt-0 pt-0"
                v-if="preValueList.OS_check == true && preValueList.assisted_by2 == true"
                :readonly="readOnlyField === true"
              ></v-text-field>
              <!-- assisted_by2_os -->
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="preValueList.assisted_by"
                :readonly="readOnlyField === true"
                label="Assisted By OD"
                hide-details
                class="mt-0 pt-0"
                v-if="preValueList.OD_check == true && preValueList.assisted_by2 == true"
              ></v-text-field>
              <!-- assisted_by2_od -->
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-1">
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" class="float-left" text @click="refresh1()"> Previous </v-btn>
              <v-btn color="primary" class="mr-3" v-if="!(preValueList.ebsr_donor_id)" @click="formCThird()"> Save </v-btn>
              <v-btn color="primary" text @click="e1 = 4"> Next </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card-title class="justify-start">
            <span class="font-weight-semibold">EYE RECOVERY INFORMATION</span>
          </v-card-title>
          <v-row class="mx-3 pt-3">
            <v-col cols="12" md="3">
              <p class="mt-1">Recovery Method*:</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                :items="recoveryMethodList"
                hide-details
                v-model="preValueList.recovery_method"
                :readonly="readOnlyField1 === true"
                :error="errr43 === false"
                class="mt-0 pt-0"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <p>Recovery Date Time*:</p>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
              <!-- <v-datetime-picker
                v-model="recoveryDateTime"
                date-format="dd-MM-yyyy" 
                time-format="HH:mm"
                class="mt-0 pt-0"
                :text-field-props="textFieldProps"
                :error="errr44 === false"
                :readonly="readOnlyField1 === true"
              ></v-datetime-picker> -->
              <v-text-field
                v-model="recoveryDateTime"
                hide-details
                readonly
                class="mt-0 pt-0"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="3">
              <p class="mt-1">Recovery Done By:</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="preValueList.recovery_done_by"
                hide-details
                :readonly="readOnlyField1 === true"
                class="mt-0 pt-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1"> </v-col>
            <v-col cols="12" md="2">
              <p class="mt-1">Assisted By:</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="preValueList.assisted_by"
                hide-details
                class="mt-0 pt-0"
                :readonly="readOnlyField1 === true"
                 :error="errr45 === false"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="12">
              <v-textarea
                label="Comments"
                v-model="preValueList.comments"
                hide-details
                rows="3"
                row-height="15"
                :readonly="readOnlyField1 === true"
                class="mt-0 pt-0 mb-2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="3">
              <p class="mt-1">Recovery Intent*:</p>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                :items="recoveryIntentList"
                hide-details
                v-model="preValueList.recovery_intent"
                class="mt-0 pt-0"
                :readonly="readOnlyField1 === true"
                :error="errr46 === false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="3">
              <v-row>
                <v-col cols="12" md="9">
                  <v-autocomplete
                    v-model="preValueList.storage_medium"
                    :items="StorageMedium"
                    label="Storage Medium*"
                    @change="functioncall()"
                    :readonly="readOnlyField21 === true"
                    :error="error47 === false"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="pt-8 portrait" v-if="icon">
                  <v-img height="25" @click="imageOpen()" width="25" src="@/assets/images/logos/icon.jpg"></v-img>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="preValueList.lot_no"
                label="Lot No*"
                hide-details
                :readonly="readOnlyField1 === true"
                :error="error48 === false"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-datetime-picker
                v-model="expDateTimeStorageMedium"
                date-format="dd-MM-yyyy" 
                time-format="HH:mm"
                label="Storage Medium Exp Dt*"
                class="mt-0 pt-0"
                :readonly="readOnlyField1 === true"
                :error="errr49 === false"
                hide-details
              ></v-datetime-picker>
            </v-col>


            <v-col cols="12" md="3">
              <v-datetime-picker
                v-model="expDateTime"
                date-format="dd-MM-yyyy" 
                time-format="HH:mm"
                label="Exp Date (Cornea with Medium)*"
                class="mt-0 pt-0"
                :readonly="readOnlyField1 === true"
                :error="errr49 === false"
                hide-details
              ></v-datetime-picker>
            </v-col>
          </v-row>
          <!-- <v-card-title class="justify-start mt-5">
            <span class="font-weight-semibold">BLOOD SAMPLE FOR SEROLOGY TEST</span>
          </v-card-title>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <v-datetime-picker
                v-model="dateTimeOfCollection"
                time-format="HH:mm:ss"
                label="Date Time of Collection"
                :readonly="readOnlyField1 === true"
              ></v-datetime-picker>
            </v-col>
            <v-col cols="12" md="8">
              <v-radio-group row class="mt-3" v-model="preValueList.mortem" :readonly="readOnlyField1 === true">
                <v-radio name="Parents_name" label="Pre" value="Pre"></v-radio>
                <v-radio name="Parents_name" label="Post" value="Post"></v-radio>
                <span class="mt-1 body-2">Mortem</span>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="2">
              <p class="mt-1">Collected By:</p>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field 
                v-model="preValueList.collected_by" 
                hide-details
                class="mt-0 pt-0"
                :readonly="readOnlyField1 === true"
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row class="mb-1 mx-3">
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" class="float-left" text @click="refresh2"> Previous </v-btn>
              <v-btn color="primary" v-if="saveBtn" @click="same()"> save </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <!-- Laboratory infromation -->

        <!-- <v-stepper-content step="5">
           <v-card-title class="justify-center mb-10">
            <span class="text-h5"><strong>LABORATORY INFORMATION</strong></span>
          </v-card-title>

          <v-card-subtitle class="mb-0 pb-0 text-h6">
            <span class="font-weight-semibold text--primary me-1">WBC</span>
          </v-card-subtitle>
          <v-row class="mx-3 pt-3 mb-1">
            <v-col cols="12" md="4">
              <v-radio-group row hide-details class="mt-0" label="WBCs performed:"
                v-model="preValueList.wbc_performed_yes_or_no" @change="resetFieldsData11(preValueList.wbc_performed_yes_or_no)" :readonly="readOnlyField1 === true"> 
                <v-radio name="Parents_name" label="Yes" value="Yes"></v-radio>
                <v-radio name="Parents_name" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-6 mt-0" v-if="preValueList.wbc_performed_yes_or_no == 'Yes'">
            <v-col cols="12" md="4" class="pb-0">
              <p class="body-2 my-0"><strong> Date and Time</strong></p>
            </v-col>
            <v-col cols="12" md="4" class="pb-0">
              <p class="body-2 my-0"><strong>Count</strong></p>
            </v-col>
            <v-col cols="12" md="4" class="pb-0">
            </v-col>

            <template v-for="wbcObj in wbc_performed_arr_com">
              <v-col cols="12" md="4">
                <v-datetime-picker v-model="wbcObj.date_time" time-format="HH:mm:ss" :text-field-props="textFieldProps" :readonly="readOnlyField1 === true">
                </v-datetime-picker>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="wbcObj.count" hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
              </v-col>
            </template>
          </v-row>

          <v-card-subtitle class="mb-0 pb-0 pt-5 text-h6">
            <span class="font-weight-semibold text--primary me-1">Temperature</span>
          </v-card-subtitle>
          <v-row class="mx-3 pt-3 mb-1">
            <v-col cols="12" md="5">
              <v-radio-group row hide-details class="mt-0" label="Temperature Recorder"
                v-model="preValueList.temperature_recorded_yes_or_no" @change="resetFieldsData12(preValueList.temperature_recorded_yes_or_no)" :readonly="readOnlyField1 === true"> 
                <v-radio name="Parents_name" label="Yes" value="Yes"></v-radio>
                <v-radio name="Parents_name" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="5" md="4" v-if="preValueList.temperature_recorded_yes_or_no == 'No'">
              <v-text-field v-model="preValueList.temperature_recorded_no_reason" label="if No Reason" dense hide-details>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-6 mt-0" v-if="preValueList.temperature_recorded_yes_or_no == 'Yes'">
            <v-col cols="12" md="4" class="pb-0">
              <p class="body-2 my-0"><strong>Date and Time</strong></p>
            </v-col>
            <v-col cols="12" md="4" class="pb-0">
              <p class="body-2 my-0"><strong>Result</strong></p>
            </v-col>
            <v-col cols="12" md="4" class="pb-0">
              <p class="body-2 my-0"><strong>Units</strong></p>
            </v-col>
            <template v-for="tempObj in temperature_arr_com">
              <v-col cols="12" md="4">
                <v-datetime-picker v-model="tempObj.date_time" time-format="HH:mm:ss"
                  :text-field-props="textFieldProps" :readonly="readOnlyField1 === true">
                </v-datetime-picker>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="tempObj.result" hide-details :readonly="readOnlyField1 === true"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="tempObj.units" hide-details @keypress="onlyNumber" :readonly="readOnlyField1 === true"></v-text-field>
              </v-col>
            </template>
          </v-row>

          <v-card-subtitle class="mb-0 pb-0 pt-5 text-h6">
            <span class="font-weight-semibold text--primary me-1">Cultures</span>
          </v-card-subtitle>
          <v-row class="mx-3 pt-3 mb-1">
            <v-col cols="12" md="4">
              <v-radio-group hide-details row class="mt-0" label="Cultures Done"
                v-model="preValueList.cultures_done_yes_or_no" @change="resetFieldsData(preValueList.cultures_done_yes_or_no)" :readonly="readOnlyField1 === true">
                <v-radio name="Parents_name" label="Yes" value="Yes"></v-radio>
                <v-radio  name="Parents_name" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-3 mb-8 mt-0" v-if="preValueList.cultures_done_yes_or_no == 'Yes'">
            <v-col cols="12" md="4" class="pb-0">
              <p class="body-2 mt-2 mb-0"><strong>Culture Source</strong></p>
            </v-col>
            <v-col cols="12" md="4" class="pb-0">
              <p class="body-2 mt-2 mb-0"><strong>Date and Time</strong></p>
            </v-col>
            <v-col cols="12" md="4" class="pb-0">
              <p class="body-2 mt-2 mb-0"><strong>Result</strong></p>
            </v-col>
            <template v-for="culObj in cultures_source_arr_com">
              <v-col cols="12" md="4">
                <v-text-field v-model="culObj.cultures_source" hide-details :readonly="readOnlyField1 === true"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-datetime-picker v-model="culObj.date_time" time-format="HH:mm:ss" :text-field-props="textFieldProps" :readonly="readOnlyField1 === true">
                </v-datetime-picker>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="culObj.result" hide-details :readonly="readOnlyField1 === true"></v-text-field>
              </v-col>
            </template>
          </v-row>

          <v-card-actions>
            <v-btn color="primary" text @click="refresh3()"> Previous </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="saveBtn" @click="same()"> Save </v-btn>
          </v-card-actions>
       
        </v-stepper-content> -->
      </v-stepper-items>

      <v-dialog v-model="viewDonorModal" max-width="840">
        <v-card>
          <v-card-title class="justify-center"> </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    hide-details
                    label="OS"
                    class="mt-0 pt-0"
                    v-model="preValueList.OS_check"
                    :readonly="readOnlyField === true"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    hide-details
                    class="mt-0 pt-0"
                    label="OD"
                    v-model="preValueList.OD_check"
                    :readonly="readOnlyField === true"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-datetime-picker
                    v-model="PreservationDateTime"
                    label="Date and Time of Preservation"
                    date-format="dd-MM-yyyy" 
                    time-format="HH:mm"
                    class="mt-0 pt-0"
                  >
                  </v-datetime-picker>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="preValueList.lot_no"
                    label="Lot No"
                    hide-details
                    @click="saveData()"
                    :readonly="readOnlyField1 === true"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-datetime-picker
                    v-model="expDateTime"
                    label="Expiry Date and time"
                    date-format="dd-MM-yyyy" 
                    time-format="HH:mm"
                    class="mt-0 pt-0"
                    :text-field-props="textFieldProps"
                    :readonly="readOnlyField1 === true"
                  ></v-datetime-picker>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p class="justify-center d-flex"><strong> HISTORY</strong></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <!-- preValueList.history_storage_medium -->
                  <v-text-field
                    v-model="preValueList.history_storage_medium"
                    label="Storage Medium"
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="PreservationDateTime"
                    label="Date and time of Preservation"
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="preValueList.history_lot_no"
                    label="Lot No"
                    hide-details
                    readonly
                    :error="errr48 === false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="history_exp_date_time"
                    label="Expiry Date"
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="viewDonorModal = false"> Close </v-btn>
            <v-btn color="primary" @click="UpdateData()"> SAVE </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="viewDonorModal1" max-width="840">
        <v-card>
          <v-card-title class="justify-center"> </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <p class="justify-center d-flex"><strong> HISTORY </strong></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="preValueList.history_storage_medium"
                    label="Storage Medium"
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="PreservationDateTime"
                    label="Date and time of Preservation"
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="preValueList.history_lot_no"
                    label="Lot No"
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="history_exp_date_time"
                    label="Expiry Date"
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="viewDonorModal1 = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-stepper>
  </v-card>
</template>

<script>
import donorService from '@/service/Donor.service'
import { mdiAlphaICircleOutline } from '@mdi/js'
import moment from 'moment'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
const userId = localData.user_id
import { api } from '@/config/config'

export default {
  data() {
    return {
      errr:true,
      errr1:true,
      errr2:true,
      errr3:true,
      errr4:true,
      errr5:true,
      errr6:true,
      errr7:true,
      errr8:true,
      errr9:true,
      errr10:true,
      errr11:true,
      errr12:true,
      errr13:true,
      errr14:true,
      errr15:true,
      errr16:true,
      errr17:true,
      errr18:true,
      errr19:true,
      errr20:true,
      errr21:true,
      errr22:true,
      errr23:true,
      errr24:true,
      errr25:true,
      errr26:true,
      errr27:true,
      errr28:true,
      errr29:true,
      errr30:true,
      errr31:true,
      errr32:true,
      errr33:true,
      errr34:true,
      errr35:true,
      errr36:true,
      errr37:true,
      errr38:true,
      errr39:true,
      errr40:true,
      errr41:true,
      errr42:true,
      errr43:true,
      errr44:true,
      errr45:true,
      errr46:true,
      errr47:true,
      errr48:true,
      errr49:true,
      formtype:'',
      excision: '',
      icon: false,
      itemtype: '',
      icons: {
        mdiAlphaICircleOutline,
      },
      viewDonorModal: false,
      viewDonorModal1: false,
      textFieldProps: {
        // outlined: true,
        // dense: true,
        'hide-details': true,
      },
      Assisted: '',
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      e1: '1',
      OD_check: false,
      readOnlyField: false,
      readOnlyField1: false,
      readOnlyField21: false,
      saveBtn: true,
      OS_check: false,
      od_os_both: '',
      recoveryMethodList: ['Enucleation', 'In-Situ'],
      basicHygieneList: ['Good', 'Poor'],
      deathVerifiedList: ['Death Certificate', 'Onsite Verified'],
      collingMethodList: ['Not cooled', 'Refrigeration', 'Ice', 'Wet Gauze'],
      bodyProfileList: ['Average', 'Overweight', 'Underweight'],
      recoveryIntentList: ['Transplant', 'Research', 'Training'],
      Condition_of_Superior_OD: ['Unremarkable', 'Inflammation', 'Laceration', 'Edematous', 'Contusion', 'Abrasion'],
      Condition_of_Superior_OS: ['Unremarkable', 'Inflammation', 'Laceration', 'Edematous', 'Contusion', 'Abrasion'],
      Condition_of_Inferior_OD: ['Unremarkable', 'Inflammation', 'Laceration', 'Edematous', 'Contusion', 'Abrasion'],
      Condition_of_Inferior_OS: ['Unremarkable', 'Inflammation', 'Laceration', 'Edematous', 'Contusion', 'Abrasion'],
      Condition_of_Conjunctiva_OD: [
        'Unremarkable',
        'Discharge',
        'Petechia',
        'Inflammation',
        'Icteric',
        'pterygium',
        'Blood shot',
        'Edematous',
      ],
      Condition_of_Conjunctiva_OS: [
        'Unremarkable',
        'Discharge',
        'Petechia',
        'Inflammation',
        'Icteric',
        'pterygium',
        'Blood shot',
        'Edematous',
      ],
      Condition_of_Epithelium_OD: [
        'Unremarkable',
        'Exposure',
        'Sloughing',
        'Cloudiness',
        'Contact lens',
        'Glass',
        'Debris',
      ],
      Condition_of_Epithelium_OS: [
        'Unremarkable',
        'Exposure',
        'Sloughing',
        'Cloudiness',
        'Contact lens',
        'Glass',
        'Debris',
      ],
      Condition_of_Corneal_Stroma_OD: ['Unremarkable', 'Arcus', 'Infiltrate', 'Surgical Scar'],
      Condition_of_Corneal_Stroma_OS: ['Unremarkable', 'Arcus', 'Infiltrate', 'Surgical Scar'],
      Condition_of_Intraocular_OD: ['Phakic', 'Aphakic', 'Pseudophakic'],
      Condition_of_Intraocular_OS: ['Phakic', 'Aphakic', 'Pseudophakic'],
      Iris_Color_OD: ['Black', 'Brown', 'Blue', 'Grey'],
      Iris_Color_OS: ['Black', 'Brown', 'Blue', 'Grey'],
      StorageMedium: ['Cornisol', 'MK'],
      preValueList: {
        storage_medium: '',
        date_time_body_subjected_refrig: '',
        date_time_body_removed_refrig: '',
        recovery_date_time: '',
        date_time_of_collection: '',
        evidence_of_surgery_os: '',
        temperature_recorded_yes_or_no: '',
        wbc_performed_yes_or_no: '',
        cultures_done_yes_or_no: '',
        assisted_by2: '',
        assisted_by2_os: '',
        assisted_by2_od: '',
        exp_date: '',
        exp_dateStorageMedium: '',
        Preservation_DateTime: '',
        recovery_done_by:''
      },
      wbc_performed_arr: [
        { date_time: '', count: '' },
        { date_time: '', count: '' },
        { date_time: '', count: '' },
      ],
      temperature_arr: [
        { date_time: '', result: '', units: '' },
        { date_time: '', result: '', units: '' },
        { date_time: '', result: '', units: '' },
      ],
      cultures_source_arr: [
        { cultures_source: '', date_time: '', result: '' },
        { cultures_source: '', date_time: '', result: '' },
        { cultures_source: '', date_time: '', result: '' },
      ],
      //   textFieldProps:{
      //   dense:'true' ,
      //   "hide-details":true
      //  }
    }
  },

  computed: {
    subjectedRefrigeration: {
      get() {
        return moment(this.preValueList.date_time_body_subjected_refrig).format('DD-MM-YYYY HH:mm:ss')
      },
      set(value) {
        this.$set(
          this.preValueList,
          'date_time_body_subjected_refrig',
          moment(value).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss'),
        )
      },
    },

    removedRefrigeration: {
      get() {
        return moment(this.preValueList.date_time_body_removed_refrig).format('DD-MM-YYYY HH:mm:ss')
      },
      set(value) {
        this.$set(
          this.preValueList,
          'date_time_body_removed_refrig',
          moment(value).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss'),
        )
      },
    },

    recoveryDateTime: {
      get() {
        return moment.utc(this.preValueList.recovery_date_time).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(
          this.preValueList,
          'recovery_date_time',
          moment(value).format('YYYY-MM-DD HH:mm'),
        )
      },
    },

    dateTimeOfCollection: {
      get() {
        return moment(this.preValueList.date_time_of_collection).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(
          this.preValueList,
          'date_time_of_collection',
          moment(value).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss'),
        )
      },
    },

    expDateTime: {
      get() {
        return moment.utc(this.preValueList.exp_date).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(this.preValueList, 'exp_date', moment(value).format('YYYY-MM-DD HH:mm'))
      },
    },

    expDateTimeStorageMedium: {
      get() {
        return moment.utc(this.preValueList.exp_dateStorageMedium).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(this.preValueList, 'exp_dateStorageMedium', moment(value).format('YYYY-MM-DD HH:mm'))
      },
    },
    
    PreservationDateTime: {
      get() {
        return moment.utc(this.preValueList.Preservation_DateTime).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(this.preValueList, 'Preservation_DateTime', moment(value).format('YYYY-MM-DD HH:mm'),)
      },
    },

    history_exp_date_time:{
      get() {
        return moment.utc(this.preValueList.history_exp_date).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(
          this.preValueList,
          'history_exp_date',
          moment(value).format('YYYY-MM-DD HH:mm'),
        )
      },
    },

    temperature_arr_com: function () {
      return this.temperature_arr.map(val => {
        val.date_time = val.date_time ? moment(val.date_time).format('YYYY-MM-DD HH:mm:ss') : ''
        return val
      })
    },
    wbc_performed_arr_com: function () {
      return this.wbc_performed_arr.map(val => {
        val.date_time = val.date_time ? moment(val.date_time).format('YYYY-MM-DD HH:mm:ss') : ''
        return val
      })
    },
    cultures_source_arr_com: function () {
      return this.cultures_source_arr.map(val => {
        val.date_time = val.date_time ? moment(val.date_time).format('YYYY-MM-DD HH:mm:ss') : ''
        return val
      })
    },

    WBc_performed: function () {
      return this.Wbc_arr.map(val => {
        val.date_time = val.date_time ? moment(val.date_time).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss') : ''
        return val
      })
    },

    cultures_done: function () {
      return this.cultures_done_arr.map(val => {
        val.date_time = val.date_time ? moment(val.date_time).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss') : ''
        return val
      })
    },

    temperature_recorded: function () {
      return this.temperature_recorded_arr.map(val => {
        val.date_time = val.date_time ? moment(val.date_time).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss') : ''
        return val
      })
    },
  },

  watch: {
    options: {
      handler() {
        this.donorDeatilView()
        this.checkOsOd()
        this.checkOdOsTrue()
        this.getdonorList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.donorDeatilView()
    this.checkOsOd()
    this.checkOdOsTrue()
    this.getdonorList()
  },

  methods: {
    saveData() {
      this.preValueList.exp_date = this.currentdate()
    },
    functioncall() {
      this.popupitem()
      this.dataitemm()
    },

    currentdate() {
      const d = new Date(this.preValueList.Preservation_DateTime)
      d.setDate(d.getDate() + 14)
      return d
    },
    Cornisol() {
      const d = new Date(this.excision)
      d.setDate(d.getDate() + 14)
      return d
    },
    MK() {
      const d = new Date(this.excision)
      d.setDate(d.getDate() + 4)
      return d
    },
    Cornisol1() {
      const d = new Date(this.excision)
      d.setDate(d.getDate() + 10)
      return d
    },
    MK1() {
      const d = new Date(this.excision)
      d.setDate(d.getDate() + 4)
      return d
    },

    dataitemm() {
      if (this.preValueList.recovery_intent === 'Transplant') {
        if (this.preValueList.storage_medium === 'Cornisol') {
          this.preValueList.exp_date = this.Cornisol()
        } else if (this.preValueList.storage_medium === 'MK') {
          this.preValueList.exp_date = this.MK()
        }
      } else if (this.preValueList.recovery_intent === 'Research' || this.preValueList.recovery_intent === 'Training') {
        if (this.preValueList.storage_medium === 'Cornisol') {
          this.preValueList.exp_date = this.Cornisol1()
        } else if (this.preValueList.storage_medium === 'MK') {
          this.preValueList.exp_date = this.MK1()
        }
      }
    },
    refresh() {
      this.e1 = 1
      this.donorDeatilView()
    },
    refresh1() {
      this.e1 = 2
      this.donorDeatilView()
    },
    refresh2() {
      this.e1 = 3
      this.donorDeatilView()
    },
    refresh3() {
      this.e1 = 4
      this.donorDeatilView()
    },
    imageOpen() {
      this.viewDonorModal1 = true
    },

    popupitem() {
      if (this.itemtype == '' || this.itemtype === null) {
        this.viewDonorModal = false
      } else if (this.itemtype != '') {
        if (this.preValueList.storage_medium == 'Cornisol') {
          this.viewDonorModal = true
        }
      }
    },

    //  if(item.storage_medium == 'Cornisol'){
    //       console.log("hello2")
    //       this.viewDonorModal = true
    //     }
    //     console.log("hello3")

    same() {
      if (this.preValueList.recovery_method == '' || this.preValueList.recovery_method == null) {
        this.errr43 = false
        return false, (this.snackbarText = 'Please Select Recovery method'), (this.snackbar = true)
      } else if (this.preValueList.recovery_date_time == '' || this.preValueList.recovery_date_time == null) {
        this.errr44 = false
        return false, (this.snackbarText = 'Please fill the Recovery Date Time'), (this.snackbar = true)
      }
      //  else if (this.preValueList.assisted_by == '' || this.preValueList.assisted_by == null) {
      //   this.errr45 = false
      //   return false, 
      //   (this.snackbarText = 'Please fill the Assisted By'), 
      //   (this.snackbar = true)
      // }
       else if (this.preValueList.recovery_intent == '' || this.preValueList.recovery_intent == null) {
        this.errr46 = false
        return false, (this.snackbarText = 'Please Select Recovery intent'), (this.snackbar = true)
      } else if (this.preValueList.storage_medium == '' || this.preValueList.storage_medium == null) {
        this.errr47 = false
        return false, (this.snackbarText = 'Please Select Storage Medium'), (this.snackbar = true)
      } else if (this.preValueList.lot_no == '' || this.preValueList.lot_no == null) {
        this.errr48 = false
        return false, (this.snackbarText = 'Please fill the Lot No'), (this.snackbar = true)
      } else if (this.preValueList.exp_date == '' || this.preValueList.exp_date == null) {
        return false, (this.snackbarText = 'Please fill the Exp Date'), (this.snackbar = true)
      }else{
        this.errr43 = true
        this.errr44 = true
        this.errr45 = true
        this.errr46 = true
        this.errr47 = true
        this.errr48 = true
        this.errr43 = true
      }
      this.formCFour()
     
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 3) {
        $event.preventDefault()
      }
    },
    rest() {
      if (type == 'No') {
        this.preValueList.if_yes_specify_os = ''
      }
    },
    rest1(type) {
      if (type == 'No') {
        this.preValueList.if_yes_specify_od = ''
      }
    },

    async checkOsOd() {
      if (this.preValueList.OD_check == true) {
        this.preValueList.od_os_both = 1
      }
      if (this.preValueList.OS_check == true) {
        this.preValueList.od_os_both = 2
      }
      if (this.preValueList.OD_check == true && this.preValueList.OS_check == true) {
        this.preValueList.od_os_both = 3
      }
      if (this.preValueList.OD_check == false && this.preValueList.OS_check == false) {
        this.preValueList.od_os_both = null
      }
    },

    async checkOdOsTrue() {
      if (this.preValueList.OD_check == false) {
        this.preValueList.condition_of_superior_lid_od = ''
        this.preValueList.condition_of_superior_lid_od_comments = ''
        this.preValueList.condition_of_inferior_lid_od = ''
        this.preValueList.condition_of_inferior_lid_od_comments = ''
        this.preValueList.condition_of_conjunctiva_od = ''
        this.preValueList.condition_of_conjunctiva_od_comments = ''
        this.preValueList.condition_of_epithelium_od = ''
        this.preValueList.condition_of_epithelium_od_comments = ''
        this.preValueList.condition_of_corneal_stroma_od = ''
        this.preValueList.condition_of_corneal_stroma_od_comments = ''
        this.preValueList.condition_of_intraocular_od = ''
        this.preValueList.iris_color_od = ''
        this.preValueList.pupil_diameter_mm_od = ''
        this.preValueList.abnormalities_od = ''
        this.preValueList.evidence_of_surgery_od = ''
        this.preValueList.if_yes_specify_od = ''
      }
      if (this.preValueList.OS_check == false) {
        this.preValueList.condition_of_superior_lid_os = ''
        this.preValueList.condition_of_superior_lid_os_comments = ''
        this.preValueList.condition_of_inferior_lid_os = ''
        this.preValueList.condition_of_inferior_lid_os_comments = ''
        this.preValueList.condition_of_conjunctiva_os = ''
        this.preValueList.condition_of_conjunctiva_os_comments = ''
        this.preValueList.condition_of_epithelium_os = ''
        this.preValueList.condition_of_epithelium_os_comments = ''
        this.preValueList.condition_of_corneal_stroma_os = ''
        this.preValueList.condition_of_corneal_stroma_os_comments = ''
        this.preValueList.condition_of_intraocular_os = ''
        this.preValueList.iris_color_os = ''
        this.preValueList.pupil_diameter_mm_os = ''
        this.preValueList.abnormalities_os = ''
        this.preValueList.evidence_of_surgery_os = ''
        this.preValueList.if_yes_specify_os = ''
      }
    },
    // restOnee(){
    //   if(this.preValueList.temperature_recorded_yes_or_no == 'Yes'){

    //   }

    // },

    resetFieldsData11(type) {
      if (type == 'Yes') {
        this.wbc_performed_arr = [
          { date_time: '', count: '' },
          { date_time: '', count: '' },
          { date_time: '', count: '' },
        ]
      } else {
        this.wbc_performed_arr = [
          { date_time: '', count: '' },
          { date_time: '', count: '' },
          { date_time: '', count: '' },
        ]
      }
    },
    resetFieldsData12(type) {
      if (type == 'Yes') {
        this.temperature_arr = [
          { date_time: '', result: '', units: '' },
          { date_time: '', result: '', units: '' },
          { date_time: '', result: '', units: '' },
        ]
        this.preValueList.temperature_recorded_no_reason = ''
      } else {
        this.temperature_arr = [
          { date_time: '', result: '', units: '' },
          { date_time: '', result: '', units: '' },
          { date_time: '', result: '', units: '' },
        ]
        this.preValueList.temperature_recorded_no_reason = ''
      }
    },

    resetFieldsData(type) {
      if (type == 'Yes') {
        this.cultures_source_arr = [
          { cultures_source: '', date_time: '', result: '' },
          { cultures_source: '', date_time: '', result: '' },
          { cultures_source: '', date_time: '', result: '' },
        ]
      } else {
        this.cultures_source_arr = [
          { cultures_source: '', date_time: '', result: '' },
          { cultures_source: '', date_time: '', result: '' },
          { cultures_source: '', date_time: '', result: '' },
        ]
      }
    },

    async donorDeatilView() {
      const DonorId = this.$route.params.donor_id
      const service = new donorService()
      let response = await service.FormCData(DonorId)
      this.preValueList = response
      this.excision = response.date_of_time_of_excision
      // console.log("hellobb",response.date_of_time_of_excision)
      this.itemtype = response.storage_medium

      console.log(response.history_storage_medium)
      
      if (this.itemtype != '' || this.itemtype != null) {
        if(response.history_storage_medium == "MK"){
          this.icon = true
        }
      
      }
      this.readOnlyFun()
      if (this.preValueList.od_os_both == 1) {
        this.preValueList.OD_check = true
        this.preValueList.OS_check = false
      }
      else if (this.preValueList.od_os_both == 2) {
        this.preValueList.OS_check = true
        this.preValueList.OD_check = false
      }
      else if (this.preValueList.od_os_both == 3) {
        this.preValueList.OD_check = true
        this.preValueList.OS_check = true
      }
      else if (this.preValueList.od_os_both == null) {
        this.preValueList.OD_check = false
        this.preValueList.OS_check = false
      }
      this.reandata(response)
      try {
        this.wbc_performed_arr = JSON.parse(this.preValueList.wbc_performed)
        this.cultures_source_arr = JSON.parse(this.preValueList.cultures_done)
        this.temperature_arr = JSON.parse(this.preValueList.temperature_recorded)
        // console.log("hello",this.temperature_arr)
      } catch (error) {
        console.log(error)
      }
    },
    reandata(response) {
      if (response.storage_medium == 'Cornisol') {
        this.readOnlyField21 = true
      } else {
        this.readOnlyField21 = false
      }
    },

    async readOnlyFun() {
      if (this.preValueList.od_id == '' && this.preValueList.os_id == '') {
        this.readOnlyField = false
      } else if (this.preValueList.od_id == null && this.preValueList.os_id == null) {
        this.readOnlyField = false
      } else {
        this.readOnlyField = true
      }
    },
    async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      if (response) {
        this.permissionData = response.permissions
        var formCData = this.permissionData[3]
        this.formtype = this.permissionData[6]
        if ((formCData.view == '1' && formCData.edit == null) || (formCData.view == '1' && formCData.edit == '0')) {
          this.readOnlyField1 = true
          this.saveBtn = false
        }
      } else {
        this.totallist = 0
      }
      this.listLoading = false
    },
    async formCFirst() {
      if (this.preValueList.death_verified_by == '' || this.preValueList.death_verified_by == null) {
        this.errr = false
        return false, (this.snackbarText = 'Please Select Death verified by.'), (this.snackbar = true)
      }
      if (this.preValueList.cooling_method == '' || this.preValueList.cooling_method == null) {
        this.errr1 = false
        return false, (this.snackbarText = 'Please Select Cooling Method'), (this.snackbar = true)
      }
      if (this.preValueList.cooling_method == 'Refrigeration') {
        if (
          this.preValueList.date_time_body_subjected_refrig == '' ||
          this.preValueList.date_time_body_subjected_refrig == null
        ) {
          this.errr2 =false
          return (
            false, 
            (this.snackbarText = 'Please Fill Date-Time Body Subjected to Refrigeration'), (this.snackbar = true)
          )
        }
        if (
          this.preValueList.date_time_body_removed_refrig == '' ||
          this.preValueList.date_time_body_removed_refrig == null
        ) {
          this.errr3 =false
          return (
            false,
            
             (this.snackbarText = 'Please Fill Date-Time Body Removed from Refrigeration'), (this.snackbar = true)
          )
        }else{
          this.errr2 = true
          this.errr3 = true
        }
      }else{
        this.errr = true
          this.errr1 = true
      }
      const data = {
        donor_id: this.$route.params.donor_id,
        user_id: userId,
        death_verified_by: this.preValueList.death_verified_by,
        cooling_method: this.preValueList.cooling_method,
        date_time_body_subjected_refrig: this.preValueList.date_time_body_subjected_refrig,
        date_time_body_removed_refrig: this.preValueList.date_time_body_removed_refrig,
      }
      try {
        const response = await api.post(`donor_medical/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async formCSecond() {
      if (this.preValueList.basic_hygiene == '' || this.preValueList.basic_hygiene == null) {
        this.errr4 = false
        return false, (this.snackbarText = 'Please Select Basic Hygiene.'), (this.snackbar = true)
      }
      if (this.preValueList.basic_hygiene == 'Poor') {
        if (this.preValueList.describe_if_poor == '' || this.preValueList.describe_if_poor == null) {
          this.errr5 = false
          return false, (this.snackbarText = 'Please Fill Basic Hygiene Describe.'), (this.snackbar = true)
        }else{
          this.errr5 = true
        }
      }
      if (this.preValueList.body_profile == '' || this.preValueList.body_profile == null) {
        this.errr6 = false
        return false, (this.snackbarText = 'Please Select Body Profile'), (this.snackbar = true)
      }
      if(this.preValueList.technician_performing_assessment == '' || this.preValueList.technician_performing_assessment == null){
        this.errr7 = false
        return (
          false, (this.snackbarText = 'Please fill the technician performing assessment'), (this.snackbar = true)
        )
      }
      if (this.preValueList.lymphadenopathy == '' || this.preValueList.lymphadenopathy == null) {
        this.errr8 = false
        return (
          false, (this.snackbarText = 'Please Select Lymphadenopathy(i.e Enlaged Lymph Nodes)'), (this.snackbar = true)
        )
      }
      if (this.preValueList.jaundice_or_icterus == '' || this.preValueList.jaundice_or_icterus == null) {
        this.errr9 = false 
        return false, (this.snackbarText = 'Please Select Jaundice/Icterus'), (this.snackbar = true)
      }
      if (
        this.preValueList.needle_tracks_or_ivdrug_use == '' ||
        this.preValueList.needle_tracks_or_ivdrug_use == null
      ) {
        this.errr10 = false
        return (
          false,
          (this.snackbarText = 'Please Select Needle Tracks or other Signs of IV drug use'),
          (this.snackbar = true)
        )
      }
      if (this.preValueList.white_spots_in_mouth == '' || this.preValueList.white_spots_in_mouth == null) {
        this.errr11 = false
        return false, (this.snackbarText = 'Please Select White Spots in Mouth'), (this.snackbar = true)
      }
      if (
        this.preValueList.blue_purple_or_gray_black_spots_lesions == '' ||
        this.preValueList.blue_purple_or_gray_black_spots_lesions == null
      ) {
        this.errr12 = false
        return (
          false, (this.snackbarText = 'Please Select Blue/purple or gray/black spota/lesions'), (this.snackbar = true)
        )
      }
      if (this.preValueList.evidence_of_blood_loss == '' || this.preValueList.evidence_of_blood_loss == null) {
        this.errr13 = false
        return false, (this.snackbarText = 'Please SelectEvidence of blood loss'), (this.snackbar = true)
      }
      if (
        this.preValueList.onskin_consistent_with_kaposis_sarcoma == '' ||
        this.preValueList.onskin_consistent_with_kaposis_sarcoma == null
      ) {
        this.errr14 = false
        return (
          false, (this.snackbarText = "Please Select On skin consistent with Kapsosi's Sarcoma"), (this.snackbar = true)
        )
      }
      if (
        this.preValueList.evidence_of_samllpox_vaccination_scab == '' ||
        this.preValueList.evidence_of_samllpox_vaccination_scab == null
      ) {
        this.errr15 = false
        return (
          false, (this.snackbarText = 'Please Select Evidance of smallpox vaccination or scab'), (this.snackbar = true)
        )
      }
      if (this.preValueList.red_skin_lesions == '' || this.preValueList.red_skin_lesions == null) {
        this.errr16 = false
        return false, (this.snackbarText = 'Please Select Red/skin lesions'), (this.snackbar = true)
      }
      if (this.preValueList.body_piercing == '' || this.preValueList.body_piercing == null) {
        this.errr17 = false
        return false, (this.snackbarText = 'Please Select body piercing'), (this.snackbar = true)
      }
      if (
        this.preValueList.melanomus_moles_warts_rashes == '' ||
        this.preValueList.melanomus_moles_warts_rashes == null
      ) {
        this.errr18 = false
        return false, (this.snackbarText = 'Please Select Melanomas,Moles,warts,rashes'), (this.snackbar = true)
      }
      if (this.preValueList.hospital_interventions == '' || this.preValueList.hospital_interventions == null) {
        this.errr19 = false
        return false, (this.snackbarText = 'Please Select hospital interventions'), (this.snackbar = true)
      }
      if (this.preValueList.abrasion_brusing_etc == '' || this.preValueList.abrasion_brusing_etc == null) {
        this.errr20 = false
        return false, (this.snackbarText = 'Please Select abrasion brusing etc'), (this.snackbar = true)
      }else{
        this.errr4 = true
        this.errr6 = true
        this.errr7 = true
        this.errr8 = true
        this.errr9 = true
        this.errr10 = true
        this.errr11 = true
        this.errr12 = true
        this.errr13 = true
        this.errr14 = true
        this.errr15 = true
        this.errr16 = true
        this.errr17 = true
        this.errr18 = true
        this.errr19 = true
        this.errr20 = true
      }
      const data = {
        donor_id: this.$route.params.donor_id,
        user_id: userId,
        basic_hygiene: this.preValueList.basic_hygiene,
        describe_if_poor: this.preValueList.describe_if_poor,
        body_profile: this.preValueList.body_profile,
        hospital_staff_identifying_body: this.preValueList.hospital_staff_identifying_body,
        technician_identifying_body: this.preValueList.technician_identifying_body,
        technician_performing_assessment: this.preValueList.technician_performing_assessment,
        lymphadenopathy: this.preValueList.lymphadenopathy,
        jaundice_or_icterus: this.preValueList.jaundice_or_icterus,
        needle_tracks_or_ivdrug_use: this.preValueList.needle_tracks_or_ivdrug_use,
        white_spots_in_mouth: this.preValueList.white_spots_in_mouth,
        blue_purple_or_gray_black_spots_lesions: this.preValueList.blue_purple_or_gray_black_spots_lesions,
        evidence_of_blood_loss: this.preValueList.evidence_of_blood_loss,
        onskin_consistent_with_kaposis_sarcoma: this.preValueList.onskin_consistent_with_kaposis_sarcoma,
        evidence_of_samllpox_vaccination_scab: this.preValueList.evidence_of_samllpox_vaccination_scab,
        red_skin_lesions: this.preValueList.red_skin_lesions,
        explain_if_any_answers: this.preValueList.explain_if_any_answers,
        body_piercing: this.preValueList.body_piercing,
        melanomus_moles_warts_rashes: this.preValueList.melanomus_moles_warts_rashes,
        hospital_interventions: this.preValueList.hospital_interventions,
        abrasion_brusing_etc: this.preValueList.abrasion_brusing_etc,
        gross_physical_exam_findings: this.preValueList.gross_physical_exam_findings,
      }
      try {
        const response = await api.post(`donor_medical/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async formCThird() {
      if (this.preValueList.OS_check == true) {
        if (this.preValueList.condition_of_superior_lid_os == '' || this.preValueList.condition_of_superior_lid_os == null) {
          this.errr21 = false
          return false, (this.snackbarText = 'Please Select condition of superior Lid OS'), (this.snackbar = true)
        }
        if (this.preValueList.condition_of_inferior_lid_os == '' || this.preValueList.condition_of_inferior_lid_os == null) {
          this.errr22 = false
          return false, (this.snackbarText = 'Please Select condition of Inferior Lid OS'), (this.snackbar = true)
        }
        if (this.preValueList.condition_of_conjunctiva_os == '' || this.preValueList.condition_of_conjunctiva_os == null) {
          this.errr23 = false
          return false, (this.snackbarText = 'Please Select condition of Conjunctiva OS'), (this.snackbar = true)
        }
        if (this.preValueList.condition_of_epithelium_os == '' || this.preValueList.condition_of_epithelium_os == null) {
          this.errr24 = false
          return false, (this.snackbarText = 'Please Select condition of Epithelium OS'), (this.snackbar = true)
        }
        if (this.preValueList.condition_of_corneal_stroma_os == '' || this.preValueList.condition_of_corneal_stroma_os == null) {
          this.errr25 = false
          return false, (this.snackbarText = 'Please Select condition of corneal stroma OS'), (this.snackbar = true)
        }
        if (this.preValueList.condition_of_intraocular_os == '' || this.preValueList.condition_of_intraocular_os == null) {
          this.errr26 = false
          return false, (this.snackbarText = 'Please Select condition of intraocular OS'), (this.snackbar = true)
        }
        if (this.preValueList.iris_color_os == '' || this.preValueList.iris_color_os == null) {
          this.errr27 = false
          return false, (this.snackbarText = 'Please Select condition of Iris color OS'), (this.snackbar = true)
        }
        if (this.preValueList.pupil_diameter_mm_os == '' || this.preValueList.pupil_diameter_mm_os == null) {
          this.errr28 = false
          return false, (this.snackbarText = 'Please Select condition of Pupil diameter mm OS'), (this.snackbar = true)
        }
        if (this.preValueList.evidence_of_surgery_os == '' || this.preValueList.evidence_of_surgery_os == null) {
          this.errr30 = false
          return (
            false, (this.snackbarText = 'Please Select condition of evidence of surgery OS'), (this.snackbar = true)
          )
        }
        if (this.preValueList.evidence_of_surgery_os == 'Yes') {
          if (this.preValueList.if_yes_specify_os == '' || this.preValueList.if_yes_specify_os == null) {
            this.errr31 = false
            return false, (this.snackbarText = 'Please Fill of evidence of surgery OS'), (this.snackbar = true)
          }else{
            this.errr31 = true
          }
        }else{
          this.errr21 = true
          this.errr22 = true
          this.errr23 = true
          this.errr24 = true
          this.errr25 = true
          this.errr26 = true
          this.errr27 = true
          this.errr28 = true
          this.errr30 = true
        }
      }
      if (this.preValueList.OD_check == true) {
        if (this.preValueList.condition_of_superior_lid_od == '' || this.preValueList.condition_of_superior_lid_od == null) {
          this.errr32 = false
          return false, (this.snackbarText = 'Please Select condition of superior Lid OD'), (this.snackbar = true)
        }
        if (this.preValueList.condition_of_inferior_lid_od == '' || this.preValueList.condition_of_inferior_lid_od == null) {
          this.errr33 = false
          return false, (this.snackbarText = 'Please Select condition of Inferior Lid OD'), (this.snackbar = true)
        }
        if (this.preValueList.condition_of_conjunctiva_od == '' || this.preValueList.condition_of_conjunctiva_od == null) {
          this.errr34 = false
          return false, (this.snackbarText = 'Please Select condition of Conjunctiva OD'), (this.snackbar = true)
        }
        if (this.preValueList.condition_of_epithelium_od == ''|| this.preValueList.condition_of_epithelium_od == null) {
          this.errr35 = false
          return false, (this.snackbarText = 'Please Select condition of Epithelium OD'), (this.snackbar = true)
        }
        if (this.preValueList.condition_of_corneal_stroma_od == '' || this.preValueList.condition_of_epithelium_od == null) {
          this.errr36 = false
          return false, (this.snackbarText = 'Please Select condition of corneal stroma OD'), (this.snackbar = true)
        }
        if (this.preValueList.condition_of_intraocular_od == '' || this.preValueList.condition_of_intraocular_od == null) {
          this.errr37 = false
          return false, (this.snackbarText = 'Please Select condition of intraocular OD'), (this.snackbar = true)
        }
        if (this.preValueList.iris_color_od == '' || this.preValueList.iris_color_od == null) {
          this.errr38 = false
          return false, (this.snackbarText = 'Please Select condition of Iris color OD'), (this.snackbar = true)
        }
        if (this.preValueList.pupil_diameter_mm_od == '' || this.preValueList.pupil_diameter_mm_od == null) {
          this.errr39 = false
          return false, (this.snackbarText = 'Please Select condition of Pupil diameter mm OD'), (this.snackbar = true)
        }
        if (this.preValueList.evidence_of_surgery_od == '' || this.preValueList.evidence_of_surgery_od == null) {
          this.errr41 = false
          return (
            false, (this.snackbarText = 'Please Select condition of evidence of surgery OD'), (this.snackbar = true)
          )
        }
        if (this.preValueList.evidence_of_surgery_od == 'Yes') {
          if (this.preValueList.if_yes_specify_od == '' || this.preValueList.if_yes_specify_od == null) {
            this.errr42 = false
            return (
              false, (this.snackbarText = 'Please Fill the condition of evidence of surgery OD'), (this.snackbar = true)
            )
          }else{
            this.errr42 = true
          }
        }else{
          this.errr32 = true
          this.errr33 = true
          this.errr34 = true
          this.errr35 = true
          this.errr36 = true
          this.errr37 = true
          this.errr38 = true
          this.errr39 = true
          this.errr41 = true

        }
      }
      const data = {
        donor_id: this.$route.params.donor_id,
        user_id: userId,
        od_os_both: this.preValueList.od_os_both,
        condition_of_superior_lid_od: this.preValueList.condition_of_superior_lid_od,
        condition_of_superior_lid_os: this.preValueList.condition_of_superior_lid_os,
        condition_of_superior_lid_od_comments: this.preValueList.condition_of_superior_lid_od_comments,
        condition_of_superior_lid_os_comments: this.preValueList.condition_of_superior_lid_os_comments,
        condition_of_inferior_lid_od: this.preValueList.condition_of_inferior_lid_od,
        condition_of_inferior_lid_os: this.preValueList.condition_of_inferior_lid_os,
        condition_of_inferior_lid_od_comments: this.preValueList.condition_of_inferior_lid_od_comments,
        condition_of_inferior_lid_os_comments: this.preValueList.condition_of_inferior_lid_os_comments,
        condition_of_conjunctiva_od: this.preValueList.condition_of_conjunctiva_od,
        condition_of_conjunctiva_os: this.preValueList.condition_of_conjunctiva_os,
        condition_of_conjunctiva_od_comments: this.preValueList.condition_of_conjunctiva_od_comments,
        condition_of_conjunctiva_os_comments: this.preValueList.condition_of_conjunctiva_os_comments,
        condition_of_epithelium_od: this.preValueList.condition_of_epithelium_od,
        condition_of_epithelium_os: this.preValueList.condition_of_epithelium_os,
        condition_of_epithelium_od_comments: this.preValueList.condition_of_epithelium_od_comments,
        condition_of_epithelium_os_comments: this.preValueList.condition_of_epithelium_os_comments,
        condition_of_corneal_stroma_od: this.preValueList.condition_of_corneal_stroma_od,
        condition_of_corneal_stroma_os: this.preValueList.condition_of_corneal_stroma_os,
        condition_of_corneal_stroma_od_comments: this.preValueList.condition_of_corneal_stroma_od_comments,
        condition_of_corneal_stroma_os_comments: this.preValueList.condition_of_corneal_stroma_os_comments,
        condition_of_intraocular_od: this.preValueList.condition_of_intraocular_od,
        condition_of_intraocular_os: this.preValueList.condition_of_intraocular_os,
        iris_color_od: this.preValueList.iris_color_od,
        iris_color_os: this.preValueList.iris_color_os,
        pupil_diameter_mm_od: this.preValueList.pupil_diameter_mm_od,
        pupil_diameter_mm_os: this.preValueList.pupil_diameter_mm_os,
        abnormalities_od: this.preValueList.abnormalities_od,
        abnormalities_os: this.preValueList.abnormalities_os,
        evidence_of_surgery_od: this.preValueList.evidence_of_surgery_od,
        evidence_of_surgery_os: this.preValueList.evidence_of_surgery_os,
        if_yes_specify_od: this.preValueList.if_yes_specify_od,
        if_yes_specify_os: this.preValueList.if_yes_specify_os,
        assisted_by2: this.preValueList.assisted_by2,
        assisted_by:this.preValueList.assisted_by
        // assisted_by2_os: this.preValueList.assisted_by2_os,
        // assisted_by2_od: this.preValueList.assisted_by2_od,
      }
      try {
        const response = await api.post(`donor_medical/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async formCFour() {
      // else if(this.preValueList.date_time_of_collection == "" || this.preValueList.date_time_of_collection == null){
      //   return false,
      //   this.snackbarText = "Please fill the date time of collection",
      //   this.snackbar = true
      // }
      // else if(this.preValueList.mortem == "" || this.preValueList.mortem == null){
      //   return false,
      //   this.snackbarText = "Please fill the Mortem",
      //   this.snackbar = true
      // }
      // else if(this.preValueList.collected_by == "" || this.preValueList.collected_by == null){
      //   return false,
      //   this.snackbarText = "Please fill the Collected by",
      //   this.snackbar = true
      // }
      const data = {
        donor_id: this.$route.params.donor_id,
        user_id: userId,
        recovery_method: this.preValueList.recovery_method,
        recovery_date_time: moment.utc(this.preValueList.recovery_date_time).format('YYYY-MM-DD HH:mm'),
        recovery_done_by:this.preValueList.recovery_done_by,
        assisted_by: this.preValueList.assisted_by,
        comments: this.preValueList.comments,
        recovery_intent: this.preValueList.recovery_intent,
        storage_medium: this.preValueList.storage_medium,
        lot_no: this.preValueList.lot_no,
        exp_date: moment.utc(this.preValueList.exp_date).format('YYYY-MM-DD HH:mm'),
        exp_dateStorageMedium: moment.utc(this.preValueList.exp_dateStorageMedium).format('YYYY-MM-DD HH:mm'),
      }
      try {
        const response = await api.post(`donor_medical/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.snackbar = true
          this.donorDeatilView()
          if(this.formtype.view != '0' && this.formtype.view != null){
            this.$router.push({ name: 'Hemodilution' })
          }
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async UpdateData() {
      if (this.preValueList.lot_no == '' || this.preValueList.lot_no == null) {
        this.errr48 =false
        return false, (this.snackbarText = 'Please fill the Lot No'), (this.snackbar = true)
      }
      if (this.preValueList.exp_date == '' || this.preValueList.exp_date == null) {
        return false, (this.snackbarText = 'Please fill the Exp Date'), (this.snackbar = true)
      }else{
        this.errr48 = true
      }
      var expDate = moment.utc(this.preValueList.exp_date).format("YYYY-MM-DD HH:mm")
      var prevDateTime = moment.utc(this.preValueList.Preservation_DateTime).format("YYYY-MM-DD HH:mm")
      const data = {
        donor_id: this.$route.params.donor_id,
        user_id: userId,
        Preservation_DateTime: prevDateTime,
        storage_medium: this.preValueList.storage_medium,
        lot_no: this.preValueList.lot_no,
        exp_date: expDate,
      }
      try {
        const response = await api.post(`donor_medical/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.viewDonorModal = false
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
